import Vue from 'vue'
import Vuex from 'vuex'
import { FeathersVuex } from '../feathers-client'

// Plugins
import auth from './store.auth'
import projects from './services/projects'

// Modules
//import portfolio from './modules/portfolio'
import ux from './modules/ux'

Vue.use(Vuex)
Vue.use(FeathersVuex)

export default new Vuex.Store({
  //  modules: { portfolio, ux },
  modules: { ux },
  plugins: [projects, auth]
})
