slide<template>
  <div id="design">
    <video
      controlBar={false}
      ref="stage"
      class="video-js"
      type="video/webm"
      playbackRate="0.1"
      autoplay
      controls= "false"
      src="/assets/portfolio.webm"
      @ready="onReady"
    ></video>
    <div id="stage-overlay">
      <p>
        <button class="btnDesign" @click="movePlay()">Play</button>
        <button class="btnDesign" @click="movePrevious()">Previous</button>
        <button class="btnDesign" @click="moveNext()">Next</button>
      </p>
      <Carousel
        pagination-active-color="#42b983"
        pagination-color="#b2ebd1"
        pagination-size="5"
        easing="linear"
        speed="300"
        :autoplay="true"
        :per-page="1"
        v-html="projects[this.pos + 1]"
      >
        <Slide>.</Slide>
        <Slide>.</Slide>
      </Carousel>
    </div>
    <div id="stage-nav"></div>
  </div>
</template>

<script>
import videojs from 'video.js'
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'Design',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      pos: -1,
      stageOptions: {
        "autoplay": true,
        "controls": false,
        "autoloop": true,
        "playbackRates": [0.5],
        "sources": [
          {
            src: '/assets/portfolio.webm',
            type: 'video/webm'
          }
        ]
      }
    }
  },
  setup() {
    const projects = [
      "<Slide><h1>Hi there and welcome to my portfolio.</h1><p>I'm an Industrial Designer with a background in Software Engineering, Technical Support, and Business Systems.</p><p>I reached a point in my career where Software Engineering stopped being fulfilling to me. I longed to build and create beautiful, purposeful, tangible products with killer software to power them.</p><p>I have great appreciation of all things Design - especially those with minimalistic, intuitive user experiences, and I strive to incorporate the same in the design language of the products I design.</p><p>There are quite a few layers to the designs in my portfolio - I am more than happy to discuss any or all of them in detail, just reach out and connect with me in LinkedIn to ask away.</p><p>John.</p></Slide>",

      '<Slide><h1>Need to Reverse Engineer a product?</h1><p>Or perhaps an&nbsp;add-on(s) to an existing product?&nbsp;</p><p>An off-the-shelf Drill product was reverse engineered and modelled in Solidworks. The form was slightly redesigned to provide a superior fitting for the hand while still maintaining correct scale and positioning of the enclosed technical package. </p><p>Engineering Drawings were created of the end product, and Visualisations were animated and rendered in Keyshot.</p><p>Click on the Youtube link to see the animation.</p><p><a target=_blank href=https://www.youtube.com/watch?v=WhGymeM9UcU><img src=/assets/youtube.png /></p></Slide>',

      '<Slide><h1>How many carbs are you really consuming with that meal?</h1><p>The Food Nutrition Scanner was a fascinating project that allowed consumers to have their meals scanned at public eating areas.</p><p>The scans provide detailed analysis of proteins, carbs, minerals, vitamins using a spectroscopy scanner and an online database.</p><p>The technical package was provided by the client and my task was to create an appealing, easy-to-use product that housed the technical package.</p><p>The scanner device rotated around the plate of food and uses a mixture of Machine Learning, Artifical Intelligence and Computer Vision to identify and segment the areas of food to scan.</p><p><a target=_blank href=/assets/FoodNutritionScanner.pdf><img src=/assets/pdf.png /></p></Slide>',

      '<Slide><h1>Never let your furry loved ones go hungry again.</h1><p>Introducing Kibl - An answer to the global food waste crisis where 1.3billion tonnes of food is wasted every year and is a key part to the<b>UN Sustainable Development Goal (SDG) number 12,  <i>Ensure sustainable consumption and production patterns</i></b>.  </p><p>The food sector accounts for around 30&percnt; of the world’s total energy consumption and for around 22&percnt; of total greenhouse gas emissions, so I set out to tackle this issue head-on. </p><p>Kibl grinds, shreds, mixes and bakes your leftover meal scraps into purr-fect, delicious kibble treats your pets will love.  </p><p>Kibls sleek, stylish design suits most interior decors and its operationis incredibly easy with intelligent controls and dishwasher-friendly parts.</p><p><a href=https://www.youtube.com/watch?v=D0FJkYr6-Rk><img src=/assets/youtube.png /><a target=_blank href=/assets/kibl.pdf><img src=/assets/pdf.png /></p></Slide>',

      '<Slide><h1>Reverse Engineering a product with advanced form.</h1><p>An exercise in reverse engineering an already manufactured and marketed product, these Headphones were&nbsp;quickly and easily disected to&nbsp;better understand the Form in&nbsp;component and compartmental design to better improve and&nbsp;enhance the users experience from an aesthetic perspective.</p><p>This also allowed for adapting&nbsp;and building custom&nbsp;parts that seamlessly integrated with the existing product. Engineering drawings were produced for both the original and custom&nbsp;parts, and rapid prototyping with 3D printing provided a tangible means of testing the human-centric design&nbsp;</p></Slide>',

      '<Slide><h1>A Ruby Affair</h1><p>A collection of ruby-themed jewellery pieces was designed in Solidworks and visualised in Keyshot.</p><p>Each piece was designed for their own environment while staying true to the brands design language.</p><p>Click on the Youtube link for a rendered animation of the complete collection.</p><p><a target=_blank href=https://www.youtube.com/watch?v=zSDcwIF77lw><img src=/assets/youtube.png /></p></Slide>',

      '<Slide><h1>Rapid Shoeware</h1><p>These smart shoes were modelled using a combination of Shoemaker (for the form) and Solidworks (for the technical package). </p><p>The features of this design include a lightweight, auto lace-up system and customisable illumination of the honeycomb parts.</p><p>Sensors in the sole of the shoe provided feedback to the user for analysis on users gait - their walking, and running. The shoes are charged wirelessly and interface controls are implemented in the tongue of the shoe as well as controlled via Bluetooth. This shoe design was also one of the first wearable products designed for 3D printing and this came with its own set of challenges.</p><p> Ask me for more information. </p><p><a target=_blank href=https://youtu.be/rHgw0_3aO2w><img src=/assets/youtube.png /></p></Slide>',

      '<Slide><h1>Say Hello to Mavis</h1><p>Mavis was a proof of concept based on user feedback from several local retirement homes. Working with the residence to better understand the limitations of current walkers and also to better understand the features appreciated by consumers.</p><p>Mavis was designed for indoor use to naturally blend in with the homes asthetic. </p><p>Features include central cup holder, retractable seating, and a telescopic head shade for sun and light rain cover.<p>Designed and built for comfort, many of the residence showed great interest in having Mavis in their homes.</p><p>Click the Poster links for more information</p><p><a target=_blank href=/assets/mavis.pdf><img src=/assets/pdf.png /></p></Slide>',

      '<Slide><h1>Gliding on a cushion of air.</h1><p>A modern take on mobility walkers, the Air Glyder is a concept piece that aims to provide a truly human-centric approach with convenience and functionality at its core.</p><p>Floating on a cushion of air, friction is removed and subtle obstacles (like gaps in tiles and rocky terrains) are a breeze. The reverse-facing floating design allows consumers with a clear, unobstructed view of what is in front of them. </p><p>The arced design was inspired by paralympic runners with their carbon fibred spring shoes - consumers can rely on the spring-like functionality to assist if they happen to stagger or fall. </p><p>Bluetooth functionality allows the Air Glyder to move autonomously away from the consumer once they have sat down, and to move back to the consumer when they are ready to get back up again.</p><p><a target=_blank href=/assets/airglider.pdf><img src=/assets/pdf.png /></p></Slide>',

      '<Slide><h1>Light Roller</h1><p>Another modern approach to mobility walkers, the Light Roller features hubless wheels with lighting and an aluminium extruded frame for durability and height adjustment.</p><p>LED lighting is also included in the front area of the frame for convenience, shining a light on the path ahead. </p><p>This design was influenced from the Light Cycle (Tron movie) and demonstrated at the Western Sydney University Wide Vision Exhibition.</p></Slide>',

      '<Slide><h1>Vyper</h1><p>The Vyper is another concept-piece using Biomimmicry, drawing inspiration from a rattlesnake with its strength and poise before attacking.</p><p>The large rubberised, inflatable ball is the key to the flexible movement, allowing movement while the hand trigger is held. Consumers can roll over rocky terrain with ease and comfort, the powerful LED strips light up the path ahead. Side warning lights also provided visual warnings to surrounding passer-bys in case the consumer had a fall. </p><p><a target=_blank href=/assets/vyper.pdf><img src=/assets/pdf.png /></p></Slide>'
    ]

    return {
      projects
    }
  },
  mounted() {
    this.player = videojs(
      this.$refs.stage,
      this.options,
      function onPlayerReady() {
        this.player.play()
        console.log('STAGE: onReady')
      }
    )
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    onReady(player) {
      //this.ready = true
      this.player = player
      console.log('onReady')
      player.play()
    },
    movePlay() {
      this.player.load();
      this.player.currentTime(0)
      this.player.play()
    },
    moveNext() {
      if (this.pos == 9) {
        this.pos = -1
      }

      this.pos++

      this.player.pause()

      this.player.currentTime(this.pos)
    },
    movePrevious() {
      if (this.pos == 0) {
        this.pos = 10
      }

      this.pos--

      this.player.pause()

      this.player.currentTime(this.pos)
    }
  }
}
</script>

<style>
#design {
  display: block;
  height: 100%;
  vertical-align: middle;
}
#stage-overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 50%; /* Full width (cover the whole page) */
  bottom: 100px; /* Full height (cover the whole page) */
  top: 100px;
  left: 100px;
  z-index: 55;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0px 30px 0px 30px;
  box-shadow: 0 0 50px black;
}

#stage-nav {
  position: absolute;
  display: block;
  height: 10%;
  width: 10%;
  top: 10%;
  left: 10%;
  z-index: 51;
  background-color: rgba(0, 0, 0, 0.5);
}
#stage {
  position: absolute;
  display: block;
  overflow: hidden;
  right: 0px;
  z-index: 20;
}

.VueCarousel-slide {
  position: relative;
  color: #fff;
  background: #42b983;
  font-family: Arial;
  font-size: 24px;
  text-align: center;
  min-height: 100px;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btnDesign {
  background-color: transparent; /* Green */
  border-style: solid;
  border-width: 5px;
  color: white;
  padding: 15px 32px;
  margin-right: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 150px;
}
</style>
