///////

const ux = {
  namespaced: true,

  state: () => ({
    user: {
      id: '',
      name: 'anonymous',
      lastAccessed: '',
      isAuthenticated: false
    },
    project: '', //projectID
    view: '', // { init, stage, detail } //  empty on setup to run the init()
    debug: false
  }),

  getters: {
    getUser: state => state.user,
    getView: state => state.view
  },

  actions: {
    async CHANGEVIEW({ commit }, newView) {
      console.log('View changed to: ' + newView)
      commit('changeView', newView)
    },

    async INITIALISE({ commit }) {
      console.log('initialising')
      // doSomething funky to clear the stores out etc
      commit('changeView', 'about')
    },

    async DEBUG({ commit }) {
      console.log('debug on/off ')
      // doSomething funky to clear the stores out etc
      commit('toggleDebug')
    }
  },

  mutations: {
    setUser: (state, fetchedUser) => (state.user = fetchedUser),
    changeView: (state, newView) => (state.view = newView),
    toggleDebug: state => (state.debug = !state.debug)
  }
}

export default ux
