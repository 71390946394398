import Vue from 'vue'
import VueRouter from 'vue-router'

import Design from '../views/Design.vue'

Vue.use(VueRouter)

const routes = [{ path: '/', name: 'design', component: Design }]

const router = new VueRouter({
  routes
})

export default router
