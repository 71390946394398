<template>
  <div id="app">
    <div id="nav">
      <button v-if="!user">
        <a href="https://www.linkedin.com/in/john--teague/"
          ><img src="@/assets/linkedin.png"
        /></a>
      </button>
      <router-link to="/">John Teague's Online Portfolio</router-link>
    </div>
    <div id="portfolio">
      <router-view />
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from '@vue/composition-api'

export default {
  name: 'App',

  setup(props, context) {
    const { $store } = context.root

    const user = computed(() => $store.state.auth.user)

    function logout() {
      $store.dispatch('auth/logout').then(() => {
        window.location.reload()
      })
    }
    onMounted(() => {
      $store.dispatch('auth/authenticate').catch(error => {
        if (!error.message.includes('Could not find stored JWT')) {
          console.error(error)
        }
      })
    })
    return {
      logout,
      user
    }
  }
}
</script>

<style>
#portfolio {
  height: 100%;
  width: 100%;
  z-index: 1;
  right: 0px;
  align-content: right;
  overflow: hidden;
  position: absolute;
}
#nav {
  height: 50px;
  width: 100%;
  background-color: rgba(155, 155, 155, 0.1);
  z-index: 10;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  color: white;
  text-align: center;
}

#nav a {
  color: white;
  font-family: 'Montserrat', serif;
  font-size: 32pt;
  color: white;
}
#nav button {
  display: inline-block;
  padding: 0;
  height: 50px;
  margin-right: 50px;
  vertical-align: top;
  width: 50px;
  background-color: transparent;
}
h1 {
  font-family: 'Montserrat', serif;
  font-size: 32pt;
  color: white;
}

p {
  font-family: 'Montserrat', serif;
  font-size: 18pt;
  color: white;
  font-weight: 100;
}
body {
  background-color: #000000;
  width: 100%;
  height: auto;
  bottom: 0px;
  top: 0px;
  left: 0px;
  position: absolute;
  overflow: hidden;
  z-index: 0;
}
</style>
